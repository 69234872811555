.side-nav-link>svg {
    font-size: 1.2rem;
    margin-right: 10px;
}

a.active {
    color: #727cf5 !important;
}

.mr-10-px {
    margin-right: 10px;
}

.mt--2-px {
    margin-top: -2px;
}

.mt--3-px {
    margin-top: -3px;
}

.btn-warning {
    color: #fff;
}

.btn-warning:hover {
    color: #fff;
}

.btn-warning.focus, .btn-warning:focus {
    color: #fff;
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
}

.page-title {
    line-height: 70px;
    font-weight: 600;
    font-size: 16px;
}

.w-40-px {
    width: 40px;
}

.side-nav-link>svg {
    font-size: 1.2rem;
    margin-right: 10px;
}

a.active {
    color: #727cf5 !important;
}

.w-52-px {
    width: 52px;
}

.w-150-px {
    width: 150px;
}

.w-200-px {
    width: 200px;
}

.w-220-px {
    width: 220px;
}

.w-280-px {
    width: 280px;
}

.w-50-pc {
    width: 50%;
}

.min-w-100-px {
    min-width: 100px;
}

.min-w-50-px {
    min-width: 50px;
}

.btn-icon svg {
    font-size: 17px;
    margin-top: -2px;
    margin-right: 6px;
}

.btn-drop {
    border: none;
    background: none;
    padding: 0;
}

.action-item>svg {
    margin-top: -3px;
    margin-right: 10px;
    font-size: 15px;
}

.mn-btn-icon {
    margin-top: -3px;
    margin-right: 5px;
    font-size: 15px;
}

.btn {
    min-width: 100px;
}

/* .modal-open {
    padding-right: 0 !important;
} */

textarea {
    resize: none;
}

.modal-title {
    margin: 0 !important;
}

.validation-message {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 5;
    max-width: 100%;
    padding-top: 2px;
    padding-bottom: 0;
    padding-left: .4rem;
    padding-right: .4rem;
    margin-top: .1rem;
    font-size: .8125rem;
    color: #fff;
    background-color: rgba(250, 92, 124, .9);
    border-radius: .2rem;
}

.relative-position {
    position: relative;
}

.cursor-point {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.cursor-zoom {
    cursor: zoom-in;
}

.table-point>tbody>tr {
    cursor: pointer;
}

.table-cursor-default tr {
    cursor: default;
}

.row-master {
    background-color: #fff3cd;
}

.row-master td {
    border-bottom: none;
}

.row-detail {
    background-color: #fff3cd;
}

.font-14 {
    font-size: 14px;
}

.font-17 {
    font-size: 17px;
}

.bg-avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    display: inline-block;
    padding-bottom: 0;
    vertical-align: middle;
    margin-bottom: 0;
}

.bg-img {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: inline-block;
    padding-bottom: 0;
    vertical-align: middle;
    margin-bottom: 10px;
    padding-bottom: 100%;
    width: 100%;
    border-radius: .25rem !important;
}

.trunk {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 43.2px;
}

.table-avatar>tbody>tr>td {
    padding: 0.665rem 0.95rem;
}

.table-input>tbody>tr>td {
    padding: .438rem .95rem;
}

/* Begin Swal */

.swal2-popup {
    border-radius: .25rem;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
    border-radius: .15rem !important;
    min-width: 100px;
    /* padding: .45rem .9rem; */
    /* font-size: .9rem!important; */
}

/* End Swall */

/* Begin Select */

.react-select-container {
    height: 37.38px !important;
}

.custom-select__control {
    height: 37.38px !important;
}

.custom-select__value-container {
    padding: 0 .9rem !important;
}

.custom-select__control--is-focused {
    border-color: #dee2e6 !important;
    box-shadow: none !important;
}

.custom-select__control {
    border-color: #dee2e6 !important;
}

.custom-select__control:hover {
    border-color: #dee2e6 !important;
}

.custom-select__option--is-selected {
    background-color: #727cf5 !important;
}

.custom-select__option {
    padding-left: .9rem !important;
    padding-right: .9rem !important;
}

.custom-select__multi-value {
    background-color: #727cf5 !important;
    color: #fff;
}

.custom-select__multi-value__label {
    color: #fff !important;
}

/* End Select */

.ql-editor {
    min-height: 250px !important;
    overflow: hidden;
    overflow-y: auto;
}

.input-select-radio {
    left: 0.9rem;
    position: absolute;
    margin-left: 0 !important;
}

.input-select-label {
    padding-left: 15px;
    padding-top: 1px;
    font-weight: normal;
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.table-head>thead {
    background-color: #f1f3fa;
}

/* .table-responsive {
    overflow: inherit;
} */

/* Begin Date picker */

.react-datepicker {
    border: 1px solid #e7ebf0 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #6c757d !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected .react-datepicker {
    background-color: #727cf5 !important;
    color: #fff !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range .react-datepicker__header {
    background-color: #727cf5 !important;
    color: #fff !important;
}

.react-datepicker__day-names>.react-datepicker__day-name {
    font-weight: bold !important;
}

.react-datepicker__current-month {
    color: #6c757d !important;
}

.react-datepicker__navigation-icon {
    top: 4px !important;
}

.react-datepicker__header {
    border-bottom-color: #e7ebf0 !important;
}

.react-datepicker__day--outside-month {
    opacity: .4 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #e7ebf0 !important;
}

/* End Date picker */

.no-border-radius-top-left {
    border-top-left-radius: 0;
}

.no-border-radius-bottom-left {
    border-bottom-left-radius: 0;
}

.no-border-radius {
    border-radius: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

/* Begin loading bar */

#nprogress .bar {
    background: #727cf5 !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #727cf5, 0 0 5px #727cf5 !important;
}

#nprogress .spinner-icon {
    border-top-color: #727cf5 !important;
    border-left-color: #727cf5 !important;
    display: none;
}

/* End loading bar */

.styles-module_wrapper__1I_qj {
    z-index: 1001!important;
    background-color: rgba(0, 0, 0, 0.7)!important;
}